import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { answersApi } from "./api/answers";
import { currenciesApi } from "./api/currencies";
import { fontsApi } from "./api/fonts";
import { languagesApi } from "./api/languages";
import { projectsApi } from "./api/projects";
import { questionConditionsApi } from "./api/questionConditions";
import { questionsApi } from "./api/questions";
import { quizzesApi } from "./api/quizzes";
import { resultsApi } from "./api/results";
import { userInfoApi } from "./api/userInfo";
import { authReducer } from "./auth/slice";
import appReducer from "./app/slice";
import { formFieldsApi } from "./api/formFields";
import { resultConditionsApi } from "./api/resultConditions";
import { quizConfigsApi } from "./api/quizConfigs";
import { additionsApi } from "./api/additions";
import { templatesApi } from "./api/templates";
import { tariffsApi } from "./api/tariffs";
import { leadsApi } from "./api/leads";
import { otherApi } from "./api/other";
import { integrationEmailsApi } from "./api/integrationEmails";
import { bitrix24Api } from "./api/bitrix24";
import { integrationWebhooksApi } from "./api/integrationWebhooks";
import { integrationSalesDriveApi } from "./api/integrationSalesDrive";
import { integrationKeyCrmApi } from "./api/integrationKeyCrm";

const middleware = (getDefaultMiddleware: Function) => [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //ignoredPaths: [/^templatesApi.queries.getTemplates*/],
      ignoreActions: [
        "templatesApi/executeQuery/fulfilled",
        "templatesApi/executeQuery/pending",
      ],
    },
  }),
  userInfoApi.middleware,
  projectsApi.middleware,
  currenciesApi.middleware,
  fontsApi.middleware,
  languagesApi.middleware,
  quizzesApi.middleware,
  questionsApi.middleware,
  answersApi.middleware,
  questionConditionsApi.middleware,
  resultsApi.middleware,
  formFieldsApi.middleware,
  resultConditionsApi.middleware,
  quizConfigsApi.middleware,
  additionsApi.middleware,
  templatesApi.middleware,
  tariffsApi.middleware,
  leadsApi.middleware,
  integrationEmailsApi.middleware,
  otherApi.middleware,
  bitrix24Api.middleware,
  integrationWebhooksApi.middleware,
  integrationSalesDriveApi.middleware,
  integrationKeyCrmApi.middleware
];

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["refresh", "access"],
};

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [currenciesApi.reducerPath]: currenciesApi.reducer,
    [fontsApi.reducerPath]: fontsApi.reducer,
    [languagesApi.reducerPath]: languagesApi.reducer,
    [quizzesApi.reducerPath]: quizzesApi.reducer,
    [questionsApi.reducerPath]: questionsApi.reducer,
    [answersApi.reducerPath]: answersApi.reducer,
    [questionConditionsApi.reducerPath]: questionConditionsApi.reducer,
    [resultsApi.reducerPath]: resultsApi.reducer,
    [formFieldsApi.reducerPath]: formFieldsApi.reducer,
    [resultConditionsApi.reducerPath]: resultConditionsApi.reducer,
    [quizConfigsApi.reducerPath]: quizConfigsApi.reducer,
    [additionsApi.reducerPath]: additionsApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [tariffsApi.reducerPath]: tariffsApi.reducer,
    [leadsApi.reducerPath]: leadsApi.reducer,
    [integrationEmailsApi.reducerPath]: integrationEmailsApi.reducer,
    [otherApi.reducerPath]: otherApi.reducer,
    [bitrix24Api.reducerPath]: bitrix24Api.reducer,
    [integrationWebhooksApi.reducerPath]: integrationWebhooksApi.reducer,
    [integrationSalesDriveApi.reducerPath]: integrationSalesDriveApi.reducer,
    [integrationKeyCrmApi.reducerPath]: integrationKeyCrmApi.reducer,
  },
  middleware,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const resetApiState = () => {
  const apis = [
    userInfoApi.util.resetApiState(),
    projectsApi.util.resetApiState(),
    // currenciesApi.util.resetApiState();
    // fontsApi.util.resetApiState();
    // languagesApi.util.resetApiState();
    quizzesApi.util.resetApiState(),
    questionsApi.util.resetApiState(),
    answersApi.util.resetApiState(),
    questionConditionsApi.util.resetApiState(),
    resultsApi.util.resetApiState(),
    formFieldsApi.util.resetApiState(),
    resultConditionsApi.util.resetApiState(),
    quizConfigsApi.util.resetApiState(),
    additionsApi.util.resetApiState(),
    templatesApi.util.resetApiState(),
    //tariffsApi.util.resetApiState();
    leadsApi.util.resetApiState(),
    integrationEmailsApi.util.resetApiState(),
    otherApi.util.resetApiState(),
    bitrix24Api.util.resetApiState(),
    integrationWebhooksApi.util.resetApiState(),
    integrationSalesDriveApi.util.resetApiState(),
  ];
  apis.forEach(item => store.dispatch(item));
}
